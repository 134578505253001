/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Category filter
        |------------
        */

        const lang = $('body').data('current-language');

        // data object sent to php controller
        let datasSend = {
            language: lang,
            eb_theme: "all",
            eb_type: "all"
        }

        $(document).on('change', '.custom__select', function(){

            if ($(this).hasClass('select__theme')) {
                datasSend.eb_theme = $(this).val();
            } else {
                datasSend.eb_type = $(this).val();
            }

            // ajax route
            let url = `/ajax/ebooks`;

            // Ajax request with all data
            let xhr = $.ajax({
                url: url,
                type: 'POST',
                method: 'POST',
                dataType: 'json',
                data: datasSend,
                beforeSend: () => {
                    $('#paginationContainer').hide();
                },
                success: (response, status) => {
                    $('.post__results').html(response);
                },
                error: (response, status, error) => {
                    console.log(error);
                }
            });
            
        });

    }
}
